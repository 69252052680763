import { ImageType } from 'components/atoms/Image';
import { TypographyProps } from 'components/atoms/Typography';

import { PublisherType } from '../Publisher';

/*
    Primary = case study
    Secondary = person
    Tertiary = blog post
    Tertiary_row = blog post with direction row
*/
export enum CardVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  TERTIARY_ROW = 'tertiary-row',
  CONTACT_US = 'contact-us',
}

export type CardProps = {
  image: ImageType;
  title: string;
  isCoveredImage?: boolean;
  id?: string;
  subtitle?: string;
  publisher?: PublisherType;
  children?: ReactNode;
  className?: string;
  variant?: CardVariant;
  badge?: string;
  titleAsEl?: TypographyProps['asEl'];
};

export type CardType = {
  image: ImageType;
  title: string;
  badge?: string;
};
