import React from 'react';
import FadeIn from 'framers/FadeIn';

import Image from 'components/atoms/Image';
import Typography from 'components/atoms/Typography';
import Publisher from 'components/molecules/Publisher';

import { descTypographyProps, titleTypographyProps } from './Card.constants';
import { StyledBadge, StyledCard } from './Card.styles';

import { CardProps, CardVariant } from './models.d';

const { PRIMARY, TERTIARY } = CardVariant;

const Card = ({
  variant = PRIMARY,
  image: { image, alt },
  title,
  subtitle,
  publisher,
  badge,
  children,
  isCoveredImage,
  className,
  titleAsEl,
}: CardProps) => {
  const isPrimaryVariant = variant === CardVariant.PRIMARY;
  const isTertiaryVariant = variant.includes(CardVariant.TERTIARY);

  return (
    <FadeIn>
      <StyledCard data-variant={variant} className={className} data-testid="card">
        <Image
          className="card-image"
          {...{
            image,
            alt,
            objectFit:
              isPrimaryVariant || isCoveredImage || isTertiaryVariant ? 'cover' : 'contain',
          }}
        />
        {badge ? <StyledBadge>{badge}</StyledBadge> : null}
        {variant.includes(TERTIARY) ? <Publisher {...publisher} /> : null}
        <Typography
          className="card-title"
          color="gray-900"
          {...titleTypographyProps[variant]}
          asEl={titleAsEl || titleTypographyProps[variant].asEl}
        >
          {title}
        </Typography>
        {variant.includes(TERTIARY) && subtitle ? (
          <Typography className="card-description" {...descTypographyProps}>
            {subtitle}
          </Typography>
        ) : null}
        {children}
      </StyledCard>
    </FadeIn>
  );
};

export default Card;
