import styled from 'styled-components';
import { breakpoint } from 'styles';

import Badge from 'components/atoms/Badge';
import { StyledButton } from 'components/atoms/Button';
import { CardVariant } from 'components/molecules/Card/models.d';
import { StyledAuthorSection } from 'components/molecules/Publisher';

const { SECONDARY, TERTIARY, TERTIARY_ROW, CONTACT_US } = CardVariant;

export const StyledCard = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 198px repeat(2, auto);
  width: 100%;
  min-width: 230px;

  & > *:last-child {
    justify-self: center;
  }

  ${breakpoint.md} {
    min-width: unset;
  }

  ${breakpoint.lg} {
    grid-template-rows: 264px repeat(2, auto);
  }

  img {
    transition: transform 1s ease !important;
  }

  &:hover {
    ${StyledButton}::after {
      background-color: var(--color);
      transform: scaleX(1);
      transform-origin: left;
    }

    img {
      transform: scale(1.1);
    }
  }

  &[data-variant='${SECONDARY}'],
  &[data-variant='${CONTACT_US}'] {
    .card-image {
      z-index: var(--z-index-ground);
    }

    &:hover {
      img {
        transform: scale(1);
      }
    }

    ${breakpoint.sm} {
      grid-template-rows: 326px repeat(2, auto);
    }

    ${breakpoint.lg} {
      grid-template-rows: 429px repeat(2, auto);
    }
  }

  &[data-variant*='${TERTIARY}'] {
    & > *:last-child {
      justify-self: start;
    }

    ${StyledButton} {
      transform: translateX(-25px);

      ${breakpoint.lg} {
        transform: translateX(-20px);
      }
    }

    .card-title {
      margin-bottom: var(--gap-xxxxs);
      overflow: hidden;
      text-overflow: ellipsis;
      line-clamp: 2;
    }
  }

  &[data-variant='${TERTIARY_ROW}'] {
    grid-template-rows: 264px repeat(2, auto);

    ${breakpoint.md} {
      grid-template-areas: 'image author' 'image title' 'image description' 'image button';
      grid-template-rows: auto;
      /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
      grid-template-columns: repeat(2, 1fr);
      column-gap: var(--gap-xxs);
      min-height: 280px;

      ${StyledAuthorSection} {
        grid-area: author;
        margin-top: var(--gap-xxxxs);
        margin-left: var(--gap-xxs);
      }

      ${StyledButton} {
        grid-area: button;
        margin-bottom: var(--gap-xxxxs);

        ${breakpoint.md} {
          transform: translateX(-10px);
        }

        ${breakpoint.lg} {
          transform: translateX(0px);
        }
      }

      .card-image {
        grid-area: image;
      }

      .card-title {
        grid-area: title;
        margin-left: var(--gap-xxs);
        letter-spacing: -0.02em;
      }

      .card-description {
        grid-area: description;
        margin-left: var(--gap-xxs);
      }
    }

    ${breakpoint.lg} {
      min-height: 376px;

      ${StyledAuthorSection} {
        margin-top: var(--gap-sm);
      }

      ${StyledButton} {
        margin-bottom: var(--gap-sm);
      }
    }

    ${breakpoint.xl} {
      min-height: 440px;

      .card-title,
      .card-description {
        margin-left: var(--gap-lg);
      }

      ${StyledAuthorSection} {
        margin-top: var(--gap-lg);
        margin-left: var(--gap-lg);
      }

      ${StyledButton} {
        margin-bottom: var(--gap-lg);
        margin-left: var(--gap-xs);
      }
    }
  }
`;

export const StyledBadge = styled(Badge)`
  position: absolute;
  top: var(--gap-xxs);
  left: var(--gap-xxs);
  z-index: 2;

  ${breakpoint.xl} {
    left: var(--gap-md);
  }
`;
