import { TypographyProps } from 'components/atoms/Typography';

import { CardVariant } from './models.d';

export const titleTypographyProps: Record<CardVariant, TypographyProps> = {
  primary: {
    asEl: 'h3',
    size: { base: 20, lg: 30 },
    padding: { left: 16, right: 16, top: { base: 12, lg: 32 } },
    fontWeight: 500,
    align: 'center',
  },
  secondary: {
    asEl: 'h3',
    size: { base: 24, lg: 30 },
    padding: { left: 8, right: 8, top: { base: 16, md: 24, lg: 40 } },
    fontWeight: 400,
    align: 'center',
  },
  tertiary: {
    asEl: 'h2',
    lineHeight: 1.3,
    size: { base: 18, md: 24, lg: 30 },
    padding: { top: { base: 16, md: 24 } },
    fontWeight: 500,
    align: 'left',
  },
  'tertiary-row': {
    asEl: 'h2',
    lineHeight: 1.2,
    size: { base: 24, md: 30, lg: 36, xl: 48 },
    padding: { top: { base: 16, md: 24 } },
    fontWeight: 500,
    align: 'left',
  },
  'contact-us': {
    asEl: 'h2',
    size: { base: 24, lg: 30 },
    padding: { left: 8, right: 8, top: { base: 16, md: 24, lg: 40 } },
    fontWeight: 400,
    align: 'center',
  },
};

export const descTypographyProps: TypographyProps = {
  color: 'gray-600',
  size: { base: 16, lg: 18 },
  padding: { bottom: 24 },
  fontWeight: 400,
};
