import React from 'react';

import { authorTypographyProps, timeTypographyProps } from './Publisher.constants';
import { StyledAuthorSection, StyledAvatar, StyledTypography } from './Publisher.styles';

import { PublisherType } from './models.d';

const Publisher = ({ image, publisher, time }: PublisherType) => (
  <StyledAuthorSection data-testid="publisher">
    {image ? <StyledAvatar alt={image.alt} image={image.image} /> : null}
    {publisher ? (
      <StyledTypography data-type="author" color="gray-900" {...authorTypographyProps}>
        {publisher}
      </StyledTypography>
    ) : null}
    {time ? (
      <StyledTypography data-type="time" color="gray-600" {...timeTypographyProps}>
        {new Date(time).toLocaleDateString()}
      </StyledTypography>
    ) : null}
  </StyledAuthorSection>
);

export default Publisher;
