import React, { FC } from 'react';

import Typography from 'components/atoms/Typography';

import { StyledBadge } from './Badge.styles';

import { BadgeProps } from './models.d';

const Badge: FC<BadgeProps> = ({ children, className }) => (
  <StyledBadge className={className}>
    <Typography
      asEl="span"
      size={{ base: 14, md: 18 }}
      lineHeight={1.3}
      padding={{ top: 2, bottom: 2, left: 12, right: 12 }}
      color="gray-700"
      fontWeight={500}
    >
      {children}
    </Typography>
  </StyledBadge>
);

export default Badge;
