import { TypographyProps } from 'components/atoms/Typography';

export const authorTypographyProps: TypographyProps = {
  color: 'gray-900',
  size: { base: 16, lg: 20 },
  fontWeight: 400,
  lineHeight: 1.3,
};

export const timeTypographyProps: TypographyProps = {
  color: 'gray-600',
  size: { base: 14, lg: 18 },
  fontWeight: 400,
  lineHeight: 1.3,
};
