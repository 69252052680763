import styled from 'styled-components';
import { breakpoint } from 'styles';

import Image from 'components/atoms/Image';
import Typography from 'components/atoms/Typography';

export const StyledTypography = styled(Typography)`
  &[data-type='author'] {
    grid-area: name;
  }

  &[data-type='time'] {
    grid-area: time;

    ${breakpoint.lg} {
      &::before {
        padding-right: var(--gap-xxs);
        content: '• ';
      }
    }
  }
`;

export const StyledAuthorSection = styled.div`
  display: grid;
  grid-template-areas: 'image name' 'image time';
  grid-template-columns: 44px auto;
  column-gap: var(--gap-sm);
  align-items: center;
  margin-top: var(--gap-xxs);

  ${breakpoint.md} {
    margin-top: var(--gap-sm);
  }

  ${breakpoint.lg} {
    grid-template-areas: 'image name time';
    grid-template-columns: 24px auto 1fr;
    align-items: end;
  }
`;

export const StyledAvatar = styled(Image)`
  grid-area: image;
  width: 44px;
  height: 44px;
  border-radius: 50%;

  ${breakpoint.lg} {
    width: 24px;
    height: 24px;
  }
`;
